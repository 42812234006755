import React from 'react';

export const GoogleIconSmall = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
		>
			<title>GoogleSmallLogo</title>
			<path
				d="M9.56214 16.0001C9.56214 14.6128 9.94435 13.3133 10.6084 12.2005V7.48056H5.88839C4.01507 9.9135 2.99963 12.8786 2.99963 16.0001C2.99963 19.1215 4.01507 22.0866 5.88839 24.5196H10.6084V19.7996C9.94435 18.6869 9.56214 17.3873 9.56214 16.0001Z"
				fill="#FBBD00"
			/>
			<path
				d="M16.9996 23.4375L13.7184 26.7187L16.9996 30C20.1211 30 23.0862 28.9846 25.5191 27.1112V22.3963H20.8042C19.6817 23.0627 18.3767 23.4375 16.9996 23.4375Z"
				fill="#0F9D58"
			/>
			<path
				d="M10.6078 19.7995L5.88782 24.5195C6.25871 25.0012 6.66258 25.4625 7.09958 25.8995C9.74383 28.5437 13.2595 30 16.9991 30V23.4375C14.2852 23.4375 11.9067 21.9763 10.6078 19.7995Z"
				fill="#31AA52"
			/>
			<path
				d="M31.0007 16C31.0007 15.1482 30.9236 14.2949 30.7715 13.4637L30.6484 12.7911H17.0007V19.3536H23.6426C22.9977 20.6367 22.006 21.6834 20.8052 22.3963L25.5202 27.1113C26.0019 26.7404 26.4632 26.3365 26.9002 25.8995C29.5445 23.2552 31.0007 19.7395 31.0007 16Z"
				fill="#3C79E6"
			/>
			<path
				d="M22.2588 10.7409L22.8388 11.3209L27.4792 6.68054L26.8992 6.10052C24.2549 3.45627 20.7392 2 16.9996 2L13.7184 5.28125L16.9996 8.5625C18.9862 8.5625 20.854 9.33611 22.2588 10.7409Z"
				fill="#CF2D48"
			/>
			<path
				d="M16.9991 8.5625V2C13.2596 2 9.74389 3.45627 7.09958 6.10047C6.66258 6.53748 6.25871 6.99877 5.88782 7.48051L10.6078 12.2005C11.9068 10.0238 14.2853 8.5625 16.9991 8.5625Z"
				fill="#EB4132"
			/>
		</svg>
	);
};
