import { Button } from '@xo-union/tk-component-buttons';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import React, { memo, useRef, type FC } from 'react';
import Styles from './styles.scss';

interface ReviewPromptBoxProps {
	title: string;
	message: string;
	handleClick: () => void;
}

export const ReviewPromptBox: FC<ReviewPromptBoxProps> = memo(
	({ title, message, handleClick }) => {
		const ref = useRef(null);

		return (
			<div className={Styles.reviewPromptBox} ref={ref}>
				<Editorial bold>{title}</Editorial>
				<Body1>{message}</Body1>
				<Button color="secondary" size="md" onClick={handleClick}>
					Write a review
				</Button>
			</div>
		);
	},
);
