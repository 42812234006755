import React from 'react';

export const TKIconSmall = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<title>TheKnotSmallLogo</title>
			<g opacity="0.8">
				<circle cx="16" cy="15.9997" r="16" fill="#FF44CB" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M22.3888 20.1533C22.3581 20.1412 22.3237 20.1551 22.311 20.1853C21.9739 20.984 21.0694 23.3172 18.5493 23.3172C15.8085 23.3172 13.216 19.6605 12.1028 17.9634C12.0835 17.9338 12.1112 17.8957 12.1456 17.906C14.0265 18.4507 16.9572 18.3818 18.82 17.0728C20.1841 16.1145 21.559 14.834 22.3991 11.6701C22.4099 11.6284 22.3846 11.5848 22.3424 11.5746L19.1282 10.8116C19.0866 10.8019 19.0456 10.8273 19.0359 10.869C18.5631 12.8878 17.143 15.3799 13.3945 14.6351C13.3752 14.6314 13.3613 14.6133 13.3631 14.594L14.059 5.81391C14.0621 5.774 14.0343 5.73833 13.9951 5.73168L10.7417 5.18633C10.6971 5.17907 10.6561 5.21112 10.6525 5.25586L10.009 13.1326C10.0066 13.1647 9.96862 13.1798 9.94511 13.158C9.56459 12.811 9.06708 12.2566 8.67149 11.8013C8.64676 11.7723 8.60515 11.7668 8.57319 11.7874C8.20895 12.0196 6.16284 13.3237 5.79499 13.5583C5.76363 13.5782 5.75097 13.6218 5.76725 13.655C6.53733 15.2578 8.40253 18.5432 9.41684 19.9846C9.43735 20.0142 9.447 20.0487 9.44398 20.085C9.39755 20.6575 9.00135 25.5137 8.94888 26.1558C8.94587 26.1957 8.97361 26.2308 9.01281 26.2374L12.3151 26.7985C12.3597 26.8063 12.4013 26.7737 12.4043 26.7283L12.6057 24.1552C12.6081 24.1225 12.6467 24.108 12.6702 24.131C14.2882 25.689 16.1431 26.7852 18.1048 26.813C19.9429 26.839 23.4924 26.4436 25.5301 21.4937C25.5427 21.4629 25.5276 21.4266 25.4969 21.4139L22.3888 20.1533Z"
					fill="black"
				/>
			</g>
		</svg>
	);
};
