import { BestOfWeddingsExpandedBadge } from '@components/bow-badge/bow-expanded-badge';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-scroll';
import { SearchConstants } from '../../../../../../../settings';
import ReviewRating from '../review_rating';
import ReviewStars from '../review_stars';
import ReviewsCount from '../reviews_count';
import Styles from './styles.scss';

export default class ReviewSummary extends PureComponent {
	static propTypes = {
		vendor: PropTypes.shape({
			awards: PropTypes.array,
		}).isRequired,
		rating: PropTypes.number.isRequired,
		totalCount: PropTypes.number.isRequired,
		extendedBow: PropTypes.bool,
		starSize: PropTypes.string,
		showLabelCount: PropTypes.bool,
		showBow: PropTypes.bool,
		showNew: PropTypes.bool,
	};

	static defaultProps = {
		extendedBow: false,
		showBow: true,
		showNew: false,
	};

	bowIcon() {
		if (this.props.extendedBow) {
			return <BestOfWeddingsExpandedBadge />;
		}

		return this.props.vendor.awards.length ? (
			<img
				className={Styles.bowIcon}
				alt={SearchConstants.BOW_FILTER_SLUG}
				src={`https://media-api.xogrp.com/images/${SearchConstants.BOW_MEDAL_MEDIA_ID}`}
			/>
		) : null;
	}

	getScreenReaderText() {
		const { rating, totalCount } = this.props;
		const shouldPluralize = totalCount !== 1;

		return `${rating} out of 5 stars and ${totalCount} review${
			shouldPluralize ? 's' : ''
		}`;
	}

	render() {
		const { rating, totalCount, starSize, showLabelCount, showBow, showNew } =
			this.props;
		return (
			<div className={Styles.reviewSummaryWrapper}>
				{!showNew && (
					<Link href="#reviews" to="reviews" smooth offset={-50}>
						<ScreenReaderOnly>{this.getScreenReaderText()}</ScreenReaderOnly>
						<ReviewStars overallRating={rating} size={starSize} />
						{rating > 0 && <ReviewRating overallRating={rating} />}
						{showLabelCount && (
							<span className={Styles.separatorCount}> · </span>
						)}
						<ReviewsCount
							reviewsCount={totalCount}
							showLabel={showLabelCount}
						/>
					</Link>
				)}
				{showNew && (
					<Body1 className={Styles.reviewBadgeNew} bold>
						<Icon
							size="md"
							name="star_filled"
							className={Styles.starFillingColor}
						/>
						<span>New</span>
					</Body1>
				)}
				{showBow && this.bowIcon()}
			</div>
		);
	}
}
