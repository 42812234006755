import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC } from 'react';
import { GoogleIconSmall } from '../../GoogleIconSmall';
import { TKIconSmall } from '../../TKIconSmall';
import Styles from './GoogleReviewsToggleSelector.scss';

export interface OwnProps {
	showTKReviews: boolean;
	setShowTKReviews: React.Dispatch<React.SetStateAction<boolean>>;
	rating: number;
	reviewsCount: number;
	googleRating: number;
	googleReviewsCount: number;
}

type Props = OwnProps;

export const GoogleReviewsToggleSelector: FC<Props> = ({
	showTKReviews,
	setShowTKReviews,
	reviewsCount,
	rating,
	googleReviewsCount,
	googleRating,
}) => {
	const impressionRef = useTrackStorefrontImpression('Google Reviews');
	const isMobile = useAppSelector((state) => selectIsMobile(state));

	const { track } = useAnalyticsContext();

	const onClickTKReviews = () => {
		if (!showTKReviews) {
			setShowTKReviews(true);
			track({
				event: 'Review Interaction',
				properties: {
					selection: 'Reviews Block',
					action: 'Click TK Reviews Tab',
				},
			});
		}
	};
	const onClickGoogleReviews = () => {
		if (showTKReviews) {
			setShowTKReviews(false);
			track({
				event: 'Review Interaction',
				properties: {
					selection: 'Reviews Block',
					action: 'Click Google Reviews Tab',
				},
			});
		}
	};

	const hasTkReviews = reviewsCount > 0;

	return (
		<div
			className={Styles.reviewTabsContainer}
			ref={impressionRef}
			data-testid="google-reviews-toggle-selector"
		>
			<button
				type="button"
				tabIndex={0}
				className={classNames(
					Styles.reviewTab,
					showTKReviews ? Styles.active : '',
				)}
				onClick={() => onClickTKReviews()}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						onClickTKReviews();
					}
				}}
			>
				<div className={Styles.reviewTabLogo}>
					<TKIconSmall />
				</div>
				<div className={Styles.reviewTabContent}>
					<Body1 bold>The Knot</Body1>
					<div className={Styles.reviewTabSummary}>
						{hasTkReviews && <Body2 bold>{rating.toFixed(1)}/5</Body2>}
						<Body2 className={Styles.reviewTabRating}>
							{hasTkReviews ? '·' : ''} {reviewsCount}{' '}
							{(!isMobile || !hasTkReviews) && 'reviews'}
						</Body2>
					</div>
				</div>
			</button>
			<button
				type="button"
				tabIndex={0}
				className={classNames(
					Styles.reviewTab,
					!showTKReviews ? Styles.active : '',
				)}
				onClick={() => onClickGoogleReviews()}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						onClickGoogleReviews();
					}
				}}
			>
				<div className={Styles.reviewTabLogo}>
					<GoogleIconSmall />
				</div>
				<div className={Styles.reviewTabContent}>
					<Body1 bold>Google</Body1>
					<div className={Styles.reviewTabSummary}>
						<Body2 bold>{googleRating.toFixed(1)}/5</Body2>
						<Body2 className={Styles.reviewTabRating}>
							· {googleReviewsCount} {!isMobile && 'reviews'}
						</Body2>
					</div>
				</div>
			</button>
		</div>
	);
};
