import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { DisplayLink } from '@xo-union/tk-ui-links';
import React, { type FC } from 'react';
import { GoogleReview } from '../google_review/GoogleReview';
import Styles from './GoogleReviewsWrapper.scss';

export interface OwnProps {
	googleReviews: {
		id: string;
		authorName: string;
		publicProfilePicture: string;
		publishedAt: string;
		rating: number;
		reviewText: string;
	}[];
	googleReviewsLink: string;
}

type Props = OwnProps;

export const GoogleReviewsWrapper: FC<Props> = ({
	googleReviews,
	googleReviewsLink,
}) => {
	const { track } = useAnalyticsContext();

	const trackOnclickSeeAllGoogleReviews = () => {
		track({
			event: 'Review Interaction',
			properties: {
				action: 'Click See All Google Reviews',
			},
		});
	};

	return (
		<div
			className={Styles.googleReviewsWrapper}
			data-testid="google-reviews-wrapper"
		>
			{googleReviews.map((review) => (
				<GoogleReview
					key={review.id}
					authorName={review.authorName}
					publicProfilePicture={review.publicProfilePicture}
					publishedAt={review.publishedAt}
					rating={review.rating}
					reviewText={review.reviewText}
				/>
			))}

			<DisplayLink
				className={Styles.seeAllButton}
				color="primary"
				size="md"
				iconName="external_link"
				labelDirection="left"
				href={googleReviewsLink}
				target="_blank"
				rel="noreferrer noopener nofollow"
				onClick={() => {
					trackOnclickSeeAllGoogleReviews();
				}}
			>
				See all Google reviews
			</DisplayLink>
		</div>
	);
};
