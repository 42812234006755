import MoreOrLess from '@xo-union/tk-component-more-or-less';
import { Stars } from '@xo-union/tk-component-vendor-card';
import { Body2 } from '@xo-union/tk-ui-typography';
import { format } from 'date-fns';
import React, { type FC } from 'react';
import ACTIONS from '../../../../../constants';
import Styles from './GoogleReview.scss';
export interface OwnProps {
	authorName: string;
	publicProfilePicture: string;
	publishedAt: string;
	rating: number;
	reviewText: string;
}

type Props = OwnProps;

export const GoogleReview: FC<Props> = ({
	authorName,
	publicProfilePicture,
	publishedAt,
	rating,
	reviewText,
}) => {
	const reviewFormattedDate = format(new Date(publishedAt), 'M/dd/yy');

	return (
		<div className={Styles.googleReview}>
			<div className={Styles.header}>
				<div className={Styles.authorInfo}>
					<div className={Styles.authorImage}>
						<img src={publicProfilePicture} alt={authorName} />
					</div>
					<Body2 bold className={Styles.authorName}>
						{authorName}
						<div className={Styles.reviewRating}>
							<Stars count={rating} />
							<span>{rating.toFixed(1)}</span>
						</div>
					</Body2>
				</div>
				<Body2 className={Styles.reviewDate}>{reviewFormattedDate}</Body2>
			</div>
			<div className={Styles.content}>
				<MoreOrLess
					content={reviewText}
					charLimit={ACTIONS.GOOGLE_REVIEW_MORE_OR_LESS_CHAR_LIMIT}
				/>
			</div>
		</div>
	);
};
